import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import Menu from "../Menu";
import apiToken from "../../Auth/apiToken";

const Banner = () => {
  const title = "Quản lý Banner";
  const [bannerPositions, setBannerPositions] = useState([]);
  const customApi = apiToken("/admin/banner");

  const handleSearchBanners = async (formData) => {
    return await customApi.post("/searchBanners", formData);
  };

  const handleDeleteBanner = async (banner_id) => {
    return await customApi.delete("/deleteBanner/" + banner_id);
  };

  const getBannerPositions = async () => {
    const response = await customApi.get("/getBannerPosition");
    setBannerPositions(response.data.data);
  };

  const handleUpsertBanner = async (formData) => {
    return await customApi.post("/upsertBanner", formData);
  };
  
  const handleGetBanner = async (banner_id) => {
    const response = await customApi.get("/getBanner/" + banner_id);
    return {
      id: response.data.id,
      position: response.data.banner_position_id,
      category: response.data.category_id,
      status: response.data.status,
      name: response.data.name,
      link: response.data.link,
      startDate: response.data.start_date.split(" ")[0],
      endDate: response.data.end_date.split(" ")[0],
      banner: response.data.source,
    };
  };

  useEffect(() => {
    getBannerPositions();
  }, []);

  return (
    <Menu      
      title={title}
      isBanner={true}
      deleteBanner={handleDeleteBanner}
      bannerPositions={bannerPositions}
      searchBanners={handleSearchBanners}
      handleUpsertBanner={handleUpsertBanner}
      handleGetBanner={handleGetBanner}
    />
  );
};
export default Banner;
