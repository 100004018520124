import React, { useState } from "react";
import { StepForwardOutlined, BorderInnerOutlined } from "@ant-design/icons";
import LayoutBanner from "./Banner/LayoutBanner";
import LayoutCategory from "./Category/LayoutCategory";
const Menu = (props) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className=" max-w-[100%] h-full max-h-24 ">
      <div className="bg-slate-300 w-full h-full min-h-screen">
        <div className="flex">
          <div
            className={`fixed top-0 left-0 h-full z-30 bg-white text-[#64748B] transition-transform duration-300 ease-in-out ${
              isOpen ? "transform translate-x-0" : "transform -translate-x-full"
            }`}
            style={{ width: "250px" }}
          >
            <div
              className="p-4 flex justify-center items-center cursor-pointer h-[150px]"
              onClick={() => (window.location.href = "/admin")}
            >
              <img
                src="../img/logo.png"
                className="h-[56px] w-[112px]"
                alt="Logo"
              />
            </div>
            <div
              className="p-4 flex items-center cursor-pointer"
              onClick={() => (window.location.href = "/admin/banners")}
            >
              <BorderInnerOutlined />
              {isOpen && <span> &nbsp; Quản lý Banner</span>}
            </div>
            <div
              className="p-4 flex items-center cursor-pointer"
              onClick={() => (window.location.href = "/admin/categories")}
            >
              <StepForwardOutlined />
              {isOpen && <span> &nbsp; Quản lý Danh mục</span>}
            </div>
            <div className="p-4 flex items-center cursor-pointer">
              <StepForwardOutlined />
              {isOpen && <span> &nbsp; Quản lý Sản phẩm</span>}
            </div>
          </div>
          <div
            className={`fixed top-0 left-0 h-full bg-white text-[#64748B] transition-transform duration-300 ease-in-out ${
              isOpen ? "transform -translate-x-full" : "transform translate-x-0"
            }`}
            style={{ width: "60px" }}
          >
            <div
              className="p-4 flex justify-center mt-[150px] cursor-pointer"
              onClick={() => (window.location.href = "/admin/banners")}
            >
              <BorderInnerOutlined />
            </div>
            <div
              className="p-4 flex justify-center cursor-pointer"
              onClick={() => (window.location.href = "/admin/categories")}
            >
              <StepForwardOutlined />
            </div>
            <div className="p-4 flex justify-center cursor-pointer">
              <StepForwardOutlined />
            </div>
          </div>

          <button
            className="fixed top-[90px] z-30 left-[-45px] mt-4 ml-4 bg-white border-2 text-white p-2 rounded-full focus:outline-none transition-transform duration-300 ease-in-out"
            style={{
              transform: isOpen ? "translateX(250px)" : "translateX(60px)",
            }}
            onClick={toggleSidebar}
          >
            {isOpen ? (
              <img
                src="../img/icon/arrow-right.png"
                className="h-[24px] w-[24px] transform -scale-x-100"
                alt="arrow-right"
              />
            ) : (
              <img
                src="../img/icon/arrow-right.png"
                className="h-[24px] w-[24px]"
                alt="arrow-right"
              />
            )}
          </button>
        </div>
        <div
          className={`transition-all duration-300 ease-in-out ${
            isOpen ? "ml-64" : "ml-16"
          } p-4`}
        >
          <div className="fixed drop-shadow-md top-0 w-screen z-10 bg-white text-[#151D48] h-[120px] ml-[-80px]">
            <div className="p-8 px-[140px] pr-[300px] flex z-20 justify-between items-center">
              <div>
                <h1 className=" text-4xl font-semibold">{props.title}</h1>
              </div>
            </div>
          </div>
          <div className=" bg-white w-full mt-[120px] rounded-md">
            <div className=" p-10">
              {props.isBanner && <LayoutBanner props={props} />}
              {props.isCategory && <LayoutCategory props={props} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
