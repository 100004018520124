import React, { useState, useEffect, useMemo } from "react";
import { InboxOutlined, DeleteOutlined } from "@ant-design/icons";
import { ToastContainer, toast } from "react-toastify";
import { Upload } from "antd";
import apiToken from "../../Auth/apiToken";

const { Dragger } = Upload;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const UpsertCategory = (props) => {
  const [categoriesLevel1, setCategoriesLevel1] = useState([]);
  const [imageList, setImageList] = useState([]);
  const [srcImage, setSrcImage] = useState([]);
  const defaultCategoryData = useMemo(
    () => ({
      id: 0,
      name: "",
      status: 1,
      level: 1,
      dependent: null,
      source: null,
      description: "",
      title: "",
      alias: "",
      description_seo: "",
    }),
    []
  );

  const [formCategoryData, setFormCategoryData] = useState(defaultCategoryData);

  const handleChangeImage = ({ fileList: newImageList }) => {
    setImageList(newImageList);
    handleSrcImage(newImageList[0]);
    setFormCategoryData({
      ...formCategoryData,
      source: newImageList[0].originFileObj,
    });
  };

  const handleSrcImage = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setSrcImage(file.preview);
  };

  const handleRemoveImg = () => {
    setImageList([]);
  };

  const handleCloseModal = () => {
    props.handleCancel();
  };

  const handleChangeFormCategoryValue = (e) => {
    const { name, value } = e.target;

    if (name === "title" && value.length > 70) {
      toast.error("Bạn phải nhập ít hơn 70 kí tự");
    } else if (name === "description_seo" && value.length > 320) {
      toast.error("Bạn phải nhập ít hơn 320 kí tự");
    } else {
      setFormCategoryData({ ...formCategoryData, [name]: value });
    }
  };

  const initData = async (categoryId) => {
    try {
      if (categoryId > 0) {
        const dataRes = await props.handleGetCategory(categoryId);
        setFormCategoryData(dataRes);
        setSrcImage(process.env.REACT_APP_API_URL + dataRes.source);
        setImageList(process.env.REACT_APP_API_URL + dataRes.source);
      } else {
        setFormCategoryData(defaultCategoryData);
        setImageList([]);
      }
    } catch (error) {
      toast.error("Có lỗi xảy ra, vui lòng thử lại sau!");
    }
  };

  const handleSubmitForm = async (e) => {
    if (imageList.length < 1) {
      return toast.error("Bạn chưa nhập hình ảnh!");
    }

    if (formCategoryData.name === "" || formCategoryData.description === "") {
      return toast.error("Bạn chưa nhập đầy đủ thông tin, hãy kiểm tra lại!");
    }

    if (formCategoryData.level == 2 && formCategoryData.dependent == null) {
      console.log(categoriesLevel1)
      if (categoriesLevel1 > 0) {
        setFormCategoryData({
          ...formCategoryData,
          dependent: categoriesLevel1[0].id,
        });
      } else {
        return toast.error("Mỗi danh mục cấp độ 2 phải chọn 1 danh mục cấp 1!");
      }
    }

    try {
      const statusTextRes = await props.handleCreateCategory(formCategoryData);
      if (statusTextRes === "OK") {
        props.getCategories();
        toast.success(
          formCategoryData.id > 0 ? "Cập nhật thành công!" : "Tạo thành công!"
        );
      } else {
        toast.success(
          formCategoryData.id > 0 ? "Cập nhật thất bại!" : "Tạo thất bại!"
        );
      }
    } catch (error) {
      toast.error("Có lỗi xảy ra, vui lòng thử lại sau!");
    }
  };

  useEffect(() => {
    initData(props.categoryId);
    setCategoriesLevel1(props.categories);
  }, [props.categoryId, props.categories]);

  return (
    <div className=" bg-white w-full mt-[120px] rounded-md">
      <div className=" px-10">
        <div className="flex justify-between">
          <p className=" font-bold text-2xl">
            {formCategoryData.id > 0 ? "Cập nhật " : "Tạo mới "} danh mục sản
            phẩm
          </p>
          <div className=" flex justify-end gap-4">
            <button
              type="button"
              onClick={handleCloseModal}
              className=" border-2 rounded-md py-2 px-5 border-blue-400 bg-white text-blue-400"
            >
              Hủy
            </button>
            <button
              type="button"
              onClick={handleSubmitForm}
              className=" border-2 rounded-md py-2 px-5 border-blue-400 bg-white text-blue-400"
            >
              {formCategoryData.id > 0 ? "Cập nhật " : "Tạo "} Danh mục
            </button>
          </div>
        </div>
        <div className=" grid grid-cols-2 ">
          <div className=" p-5 mt-7 border-2 rounded-md mr-4">
            Thông tin Danh mục
            <div className=" grid grid-cols-2 gap-4 mt-5">
              <div className="w-full">
                <label for="name">Tên Danh mục</label>
                <div className="flex items-center">
                  <input
                    id="name"
                    name="name"
                    type="text"
                    onChange={handleChangeFormCategoryValue}
                    value={formCategoryData.name}
                    className="text-[#888888] w-[522px] h-[45px] border-2 outline-none text-base px-[25px] py-[20px] rounded"
                    placeholder="Tên Danh mục"
                  />
                </div>
              </div>
              <div className="w-full">
                <label for="status">Trạng thái</label>
                <div className="flex items-center">
                  <select
                    id="status"
                    name="status"
                    onChange={handleChangeFormCategoryValue}
                    value={formCategoryData.status}
                    className="appearance-none text-[#020202] w-full h-[45px] border-2 outline-none text-base px-[15px] rounded"
                  >
                    <option value={1}> Hoạt động</option>
                    <option value={0}> Ngừng hoạt động</option>
                  </select>
                  <img
                    className="pointer-events-none ml-[-35px] h-6 w-6 "
                    src="../img/icon/arrow-right.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="w-full">
                <label for="level">Cấp độ</label>
                <div className="flex items-center">
                  <select
                    id="level"
                    name="level"
                    onChange={handleChangeFormCategoryValue}
                    value={formCategoryData.level}
                    className="appearance-none text-[#020202] w-full h-[45px] border-2 outline-none text-base px-[15px] rounded"
                  >
                    <option value={1}> 1</option>
                    <option value={2}> 2</option>
                  </select>
                  <img
                    className="pointer-events-none ml-[-35px] h-6 w-6 "
                    src="../img/icon/arrow-right.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="w-full">
                <label for="dependent">Thuộc Danh mục</label>
                <div className="flex items-center">
                  <select
                    id="dependent"
                    name="dependent"
                    onChange={handleChangeFormCategoryValue}
                    value={formCategoryData.dependent}
                    disabled={formCategoryData.level == 1}
                    className="appearance-none text-[#020202] w-full h-[45px] border-2 outline-none text-base px-[15px] rounded"
                  >
                    {categoriesLevel1.map((cate, i) => {
                      return <option value={cate.id}> {cate.name}</option>;
                    })}
                  </select>
                  <img
                    className="pointer-events-none ml-[-35px] h-6 w-6 "
                    src="../img/icon/arrow-right.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="w-full">
                <label for="name">Hình ảnh</label>
                <div className="flex items-center">
                  {imageList.length === 0 ? (
                    <Dragger
                      action={
                        process.env.REACT_APP_API_URL +
                        "/api/banner/uploadImage"
                      }
                      listType="picture-card"
                      className={imageList.length >= 1 && "hidden"}
                      onChange={handleChangeImage}
                      accept="image/*"
                      maxCount={1}
                      beforeUpload={() => false}
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                    </Dragger>
                  ) : (
                    <div className="flex">
                      <img src={srcImage} alt="img" className="max-w-[200px]" />
                      <div className="flex items-end">
                        <button
                          type="button"
                          onClick={handleRemoveImg}
                          className="mr-2 bg-none text-white w-10 font-bold py-2 px-1 rounded-full h-[45px]"
                        >
                          <DeleteOutlined
                            style={{ fontSize: "20px", color: "#888888" }}
                          />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="w-full ">
                <label for="description">Mô tả</label>
                <div className="flex items-center h-full pb-5">
                  <textarea
                    id="description"
                    name="description"
                    onChange={handleChangeFormCategoryValue}
                    value={formCategoryData.description}
                    className="text-[#888888] w-[522px] h-full border-2 outline-none text-base px-[25px] py-[20px] rounded"
                    placeholder="Nhập mô tả"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className=" p-5 mt-7 border-2 rounded-md row-span-3">
            Danh sách sản phẩm
            <div className="w-full mt-5">
              <div className="flex items-center">
                <input
                  id="name"
                  name="name"
                  type="text"
                  className="text-[#888888] w-full h-[45px] border-2 outline-none text-base px-[25px] py-[20px] rounded"
                  placeholder="Tìm kiếm sản phẩm theo Tên sản phẩm, mã SKU, Barcode của sản phẩm"
                />
              </div>
            </div>
          </div>
          <div className=" p-5 mt-7 border-2 rounded-md mr-4">
            Tối ưu SEO
            <div className="w-full mt-5">
              <div className="flex justify-between">
                <label for="title">Tiêu đề trang</label>
                <label for="title">
                  Số ký tự đã sử dụng {formCategoryData.title.length}/70
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="title"
                  name="title"
                  type="text"
                  onChange={handleChangeFormCategoryValue}
                  value={formCategoryData.title}
                  className="text-[#888888] w-full h-[45px] border-2 outline-none text-base px-[25px] py-[20px] rounded"
                  placeholder="Nhập tiêu đề"
                />
              </div>
            </div>
            <div className="w-full mt-4">
              <div className="flex justify-between">
                <label for="description_seo">Thẻ mô tả</label>
                <label for="description_seo">
                  Số ký tự đã sử dụng {formCategoryData.description_seo.length}
                  /320
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="description_seo"
                  name="description_seo"
                  onChange={handleChangeFormCategoryValue}
                  value={formCategoryData.description_seo}
                  type="text"
                  className="text-[#888888] w-full h-[45px] border-2 outline-none text-base px-[25px] py-[20px] rounded"
                  placeholder="Nhập mô tả"
                />
              </div>
            </div>
            <div className="w-full mt-4">
              <label for="alias">Đường dẫn/ Alias</label>
              <div className="flex items-center">
                <input
                  id="alias"
                  name="alias"
                  onChange={handleChangeFormCategoryValue}
                  value={formCategoryData.alias}
                  type="text"
                  className="text-[#888888] w-full h-[45px] border-2 outline-none text-base px-[25px] py-[20px] rounded"
                  placeholder="https://haha.vn"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UpsertCategory;
