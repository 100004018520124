import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Layout from "./Component/Layout";

function Register() {
  const navigate = useNavigate();
  const [errorsRes, setErrorsRes] = useState({});
  const form = {
    header1: "Bạn đã có tài khoản?",
    header2: "Đăng nhập",
  };

  const onSubmit = async (formData) => {
    try {
      console.log(formData)
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify(formData);
      await axios.post(
        process.env.REACT_APP_API_URL + "/api/register",
        body,
        config
      );
      navigate("/login");
      toast.success("Đăng kí thành công!");
    } catch (err) {
      setErrorsRes(err.response.data);
      toast.error("Có lỗi xảy ra vui lòng xảy ra!");
    }
  };

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      navigate("/");
    }
  }, []);

  return <Layout form={form} submitForm={onSubmit} errorsRes={errorsRes} isLoginForm={false} href={"/login"}/>;
}

export default Register;
