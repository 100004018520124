import React from "react";

function BlogCard(props) {

  var classImg = `object-cover mr-5 h-[${props.height}]`;
  
  return (
    <div className="p-0 mb-5">
      <img
        src="https://via.placeholder.com/800x450"
        className= {classImg}
        alt="img"
      />
        <div className="col-span-8 py-2 pl-4 text-left items-center">
          <p className="mt-2 my-2 text-[#151515] text-base font-semibold truncate">
            Thưởng thức vị ngon độc đáo cùng mì Koreno rong biển vị ngao
          </p>
          <span className="text-[#888888] font-medium text-sm overflow-hidden line-clamp-1">
            Mì Koreno rong biển vị ngao - sự kết hợp hoàn hảo giữa hương vị đậm đà của rong biển và vị ngọt thanh của ngao, mang đến trải nghiệm ẩm thực độc đáo cho
          </span>
        </div>
    </div>
  );
}

export default BlogCard;
