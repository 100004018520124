import React, { createContext, useState } from "react";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  const addToCart = (productId) => {
    const newCart = [...cart];
    const productIndex = newCart.findIndex(
      (item) => item.product_id === productId
    );

    if (productIndex >= 0) {
      newCart[productIndex].quantity += 1;
    } else {
      newCart.push({ product_id: productId, quantity: 1 });
    }

    setCart(newCart);
    localStorage.setItem("cart", JSON.stringify(newCart));
    updateTotalItems(newCart);
  };

  const updateTotalItems = (cart) => {
    const total = cart.reduce((sum, item) => sum + item.quantity, 0);
    setTotalItems(total);
  };

  React.useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem("cart")) || [];
    setCart(storedCart);
    updateTotalItems(storedCart);
  }, []);

  return (
    <CartContext.Provider value={{ cart, addToCart, totalItems }}>
      {children}
    </CartContext.Provider>
  );
};
