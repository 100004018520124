import React from 'react';
import { Result } from 'antd';
const NotFoundPage = () => (
  <Result
    status="404"
    title="404"
    subTitle="Sorry, the page you visited does not exist."
    extra={<a href="/" class="button" className="bg-cyan-500 py-4 px-3 rounded text-white">Back Home</a>}
  />
);
export default NotFoundPage;