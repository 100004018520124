import React, { useEffect, useState } from "react";
import BenefitSection from "./Component/BenefitSection";
import BlogSection from "./Component/BlogSection";
import CrazySaleSection from "./Component/CrazySaleSection";
import FlashSaleSection from "./Component/FlashSaleSection";
import Footer from "./Component/Footer";
import Header from "./Component/Header";
import HeroBanner from "./Component/HeroBanner";
import ProductsIndustrySection from "./Component/ProductsIndustrySection";
import ScrollToTopButton from "./Component/ScrollToTopButton";
import SearchBar from "./Component/SearchBar";
import SupportCenter from "./Component/SupportCenter";
import { CartProvider } from "./Context/CartContext";

function HomePage() {
  const [bannersHomePage, setBannersHomePage] = useState("Tạo mới banner");
  const [bannerIndustrySrc, setBannerIndustrySrc] = useState("");

  useEffect(() => {
    const fetchData = async (e) => {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/api/banner/getBannerHomePage"
      );
      const json = await response.json();
      setBannersHomePage(json);

      if (json.industryBanners.banners.length > 0)
        setBannerIndustrySrc(
          process.env.REACT_APP_API_URL + json.industryBanners.banners[0].source
        );
      else {
        setBannerIndustrySrc(
          "https://via.placeholder.com/1728 x 300" // + json.industryBanners.size
        );
      }
    };
    fetchData();
  }, []);

  return (
    <CartProvider>
      <Header />
      <SearchBar />
      <HeroBanner
        mainBanners={bannersHomePage.mainBanners}
        secondaryBanners={bannersHomePage.secondaryBanners}
      />
      <BenefitSection />
      <FlashSaleSection />
      <CrazySaleSection />
      <div className=" max-w-[1224px] mx-auto mt-12 flex justify-start items-center">
        <span className="mr-2 mb-0 text-[40px] text-[#151515] font-bold">
          Sản Phẩm Theo Ngành Hàng
        </span>
      </div>
      <ProductsIndustrySection industryId="1" />
      <ProductsIndustrySection industryId="2" />
      <ProductsIndustrySection industryId="3" />
      <div className="mx-auto max-w-[1728px] my-5 mt-12 flex justify-center">
        <img
          src={bannerIndustrySrc}
          className="max-h-[252px]"
          alt="Industry Banner"
        />
      </div>
      <ProductsIndustrySection industryId="4" />
      <ProductsIndustrySection industryId="5" />
      <BlogSection />
      <ScrollToTopButton />
      <SupportCenter />
      <Footer />
    </CartProvider>
  );
}

export default HomePage;
