import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FormLogin from "./FormLogin";
import FormRegister from "./FormRegister";

function Layout(props) {
  const [formTitle, setFormTitle] = useState({
    header1: "",
    header2: "",
  });
  const [isLoginForm, setIsLoginForm] = useState(true);
  const classNameInput =
    "peer w-full h-full bg-transparent text-[#151515] font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border text-[16px] px-4 pt-5 rounded-[7px] border-[#D9D9D9] focus:border-[#D9D9D9]";

  useEffect(() => {
    setFormTitle(props.form);
    setIsLoginForm(props.isLoginForm);
  }, []);

  return (
    <div className="flex h-screen w-screen gap-6 relative">
      <div className="relative">
        <img
          src="../img/auth.png"
          className=" top-0 left-0 w-full h-full object-cover shadow-md"
          alt="img"
        />
      </div>
      <div className="h-screen lg:w-auto bg-white text-[#64748B] flex flex-col justify-between space-y-4 items p-10 pl-20 my-auto">
        <div className=" flex gap-2 items-center">
          <p className=" text-[#888888] text-base">{formTitle.header1} </p>
          <a className="text-[#6B46FE] font-medium" 
              href={props.href}
          
          >{formTitle.header2}</a>
        </div>
        <div className=" w-[912px] lg:w-auto bg-white text-[#64748B] flex flex-col justify-between space-y-4 items">
          <div className=" gap-2 items-center">
            <div className=" flex items-center">
              <p className=" text-[#000000] text-[40px] text-center">
                Thật vui khi gặp lại bạn
              </p>
            </div>
            <div className=" flex mt-5">
              <p className="text-[#888888] font-medium text-base">
                Nền tảng mua sắm lý tưởng cho mọi nhu cầu của bạn
              </p>
            </div>
          </div>
          {isLoginForm ? (
            <FormLogin
              submitForm={props.submitForm}
              classNameInput={classNameInput}
            />
          ) : (
            <FormRegister
              submitForm={props.submitForm}
              errorsRes={props.errorsRes}
              classNameInput={classNameInput}
            />
          )}
        </div>
        <div className=" flex gap-1 text-xs">
          <p>Bằng việc tiếp tục, bạn đã đọc và đồng ý với </p>
          <p className="text-[#6B46FE] font-medium">Điều khoản sử dụng</p>
          <p>và</p>
          <p className="text-[#6B46FE] font-medium">
            Chính sách bảo mật thông tin
          </p>
          <p> cá nhân của Haha</p>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Layout;
