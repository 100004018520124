import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Layout from "./Component/Layout";
import apiToken from "../Auth/apiToken";

function Login() {
  const navigate = useNavigate();
  const form = {
    header1: "Bạn chưa có tài khoản?",
    header2: "Đăng ký",
  };

  const onSubmit = async (formData) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify(formData);
      await apiToken("").post(
        process.env.REACT_APP_API_URL + "/api/login",
        body,
        config
      );
      navigate("/");
    } catch (err) {
      toast.error(" Tài khoản hoặc mật khẩu không chính xác, vui lòng thử lại");
    }
  };

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      navigate("/");
    }
  }, []);
  return <Layout form={form} submitForm={onSubmit} isLoginForm={true} href={"/register"}/>;
}

export default Login;
