import React from "react";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", width:"48px", height:"48px"}}
      onClick={onClick}
    >
    <img src="../img/icon/nextArrow.png" className="mr-[15px]" alt="img"/>
    </div>
  );
}

export default NextArrow;
