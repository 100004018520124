import React, { useEffect, useState } from "react";
import { Carousel } from "antd";
import NextArrow from "../../Arrow/NextArrow";
import PrevArrow from "../../Arrow/PrevArrow";

const settings = {
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

function HeroBanner(props) {
  const [carasoulBanners, setCarasoulBanners] = useState([]);
  const [secondaryBanners, setSecondaryBanners] = useState([]);

  useEffect(() => {
    setCarasoulBanners(props.mainBanners);
    setSecondaryBanners(props.secondaryBanners);
  }, [props.mainBanners, props.secondaryBanners]);
  return (
    <div className="hero-banner bg-[#fff] text-sm py-8 mb-5 w-full max-w-[1224px] mx-auto flex justify-between ">
      <div className="max-w-[921px]">
        {carasoulBanners === undefined ||
        carasoulBanners.banners === undefined ? (
          <img
            src="https://via.placeholder.com/800x450"
            className="h-[560px] rounded-md w-[921px]"
            alt="Banner 2"
          />
        ) : (
          <Carousel arrows infinite={true} {...settings}>
            {carasoulBanners.banners.map((banner, i) => {
              return (
                <a
                  href={banner.link} 
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={process.env.REACT_APP_API_URL + banner.source}
                    className="h-[560px] rounded-md w-[921px]"
                    alt={banner.name}
                  />
                </a>
              );
            })}
          </Carousel>
        )}
      </div>
      <div className="flex-grow-1 w-[287px]">
        {secondaryBanners === undefined ||
        secondaryBanners.banners === undefined ? (
          <div>
            <img
              src="https://via.placeholder.com/800x450"
              width="287"
              height="272"
              className="h-[272px] rounded-md mb-4"
              alt="Banner 2"
            />
            <img
              src="https://via.placeholder.com/800x450"
              width="287"
              height="272"
              className="h-[272px] rounded-md"
              alt="Banner 2"
            />
          </div>
        ) : (
          <div>
            {secondaryBanners.banners.map((banner, i) => {
              return (
                <img
                  src={process.env.REACT_APP_API_URL + banner.source}
                  width="287"
                  height="272"
                  href={banner.link}
                  className="h-[272px] mb-[16px] rounded-md"
                  alt={banner.name}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

export default HeroBanner;
