import React, { useState, useEffect } from "react";
import { Carousel } from "antd";
import NextArrow from "../../Arrow/NextArrow";
import PrevArrow from "../../Arrow/PrevArrow";
import ProductCard from "../../Card/ProductCard";

const settings = {
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

function ProductsIndustrySection(prop) {

  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState([]);

  const fetchData = async () => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "/api/products-industry/" + prop.industryId
    );

    const json = await response.json();
    setProducts(json.products);
    setCategory(json.category);
  };

  useEffect(() => {
    fetchData()
  }, []);

  return (
    <div className="max-w-[1224px] mt-12 mx-auto margin-arrow-sm rounded-3">
      <div className="flex justify-start border-b-2 items-center mt-10">
        <span className="mr-2 flex items-center text-[24px] pb-[13px] border-b-2 border-[#6B46FE] text-[#151515] font-bold">
          {category.name}
          <img src="../img/icon/arrow-right.png" alt="arrow-right" />
        </span>
      </div>

      <Carousel
        arrows
        infinite={true}
        {...settings}
        className="max-w-[1224px] mt-10"
      >
        {products.map((pro, i) => {
          return (
            <div>
              <div className="grid grid-cols-4 gap-4">
                {pro.map((p, i) => {
                  return <ProductCard product={p} key={i}/>;
                })}
              </div>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
}

export default ProductsIndustrySection;
