import React, { useState, useEffect } from "react";
import apiToken from "../Auth/apiToken";
import { Outlet, useNavigate } from "react-router-dom";

const BlockLoginRoute = () => {
  const [isLogin, setIsLogin] = useState(false);
  const navigate = useNavigate();
  const getUserLogin = async () => {
    try {
      await apiToken("").post("/getUserLogin");
      setIsLogin(true);
    } catch (error) {
      console.log("Failed to fetch address");
    }
  };

  useEffect(() => {
    getUserLogin();
  }, []);

  return isLogin ? navigate("/") : <Outlet />;
};

export default BlockLoginRoute;
