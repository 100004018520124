import React from "react";
import BrandSaleCard from "../../Card/BrandSaleCard";

function CrazySaleSection() {
  return (
    <div className="max-w-[1224px] mt-12 mx-auto rounded-3">
      <div className="flex justify-between">
        <div className="flex justify-start items-center">
          <span className="mr-2 mb-0 text-[40px] text-[#151515] font-bold">
            Khuyến mãi cực sốc tại
          </span>
          <img
            src="../img/logo.png"
            className="h-[44px] w-[91px] bg-[#6b46fe] rounded-[32px]"
            alt="Logo"
          />
        </div>
        <button className="bg-[#6B46FE] rounded-[48px] max-h-[48px] border-none px-6 py-3">
          <a className="text-[#ffffff]">Xem tất cả</a>
        </button>
      </div>

      <div className="grid grid-cols-3 gap-4 mt-8">
        <div className="row-span-3">
          <BrandSaleCard height="560px"/>
        </div>
        <BrandSaleCard height="230px"/>
        <BrandSaleCard height="230px"/>
        <BrandSaleCard height="230px"/>
        <BrandSaleCard height="230px"/>
      </div>
    </div>
  );
}

export default CrazySaleSection;
