import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Radio, DatePicker, Upload, Image } from "antd";
import { toast } from "react-toastify";
import {
  EyeOutlined,
  InboxOutlined,
  DeleteOutlined,
  RedoOutlined,
} from "@ant-design/icons";
import "react-toastify/dist/ReactToastify.css";
import apiToken from '../../Auth/apiToken';

const { Dragger } = Upload;
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";
const UpsertBanner = forwardRef((props, ref) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState([]);
  const [bannerPositions, setBannerPositions] = useState([]);
  const [srcImage, setSrcImage] = useState("");
  const [productCategories, setProductCategories] = useState([]);
  const [formData, setFormData] = useState({
    id: 0,
    position: 1,
    category: 0,
    status: 1,
    name: "",
    link: "",
    startDate: "",
    endDate: "",
  });

  const [hiddenCategory, setHiddenCategory] = useState(false);

  const handlePreview = async () => {
    setPreviewImage(srcImage);
    setPreviewOpen(true);
  };
  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    handleSrcImage(newFileList[0]);
    setFormData({ ...formData, banner: newFileList[0].originFileObj });
  };
  const handleChangeValue = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "position") {
      setHiddenCategory(value == 3);
    }
  };

  const handleChangeTime = (e) => {
    setFormData({
      ...formData,
      startDate:
        e === null
          ? props.moment().format("YYYY-MM-DD")
          : e[0].format().split("T")[0],
      endDate:
        e === null
          ? props.moment().add(7, "days").format("YYYY-MM-DD")
          : e[1].format().split("T")[0],
    });
  };

  const handleSubmit = async (e) => {
    if (fileList.length < 1) {
      return toast.error("Bạn chưa nhập hình ảnh!");
    }

    if (
      formData.name === "" ||
      formData.link === "" ||
      formData.startDate === "" ||
      formData.endDate === ""
    ) {
      return toast.error("Bạn chưa nhập đầy đủ thông tin, hãy kiểm tra lại!");
    }

    if (formData.position !== 3) {
      setFormData((formData) => ({ ...formData, category: 0 }));
    }

    try {
      const response = await props.handleUpsertBanner(formData);
      if (response.statusText === "OK") {
        toast.success(
          formData.id > 0 ? "Cập nhật thành công!" : "Tạo thành công!"
        );
      } else {
        toast.success(
          formData.id > 0 ? "Cập nhật thất bại!" : "Tạo thất bại!"
        );
      }
    } catch (error) {
      toast.error("Có lỗi xảy ra, vui lòng thử lại sau!");
    }
  };

  const handleSrcImage = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setSrcImage(file.preview);
  };

  const [isHovered, setIsHovered] = useState(false);

  const handleUpload = () => {
    const uploadInputs = document.querySelectorAll(
      '.ant-upload input[type="file"]'
    );
    uploadInputs.forEach((input) => {
      input.click();
    });
  };

  const handleRemoveImg = () => {
    setFileList([]);
  };

  const getProductCategories = async () => {
    const response = await apiToken("/admin/products-categories").get("/getAllCategories");
    setProductCategories(response.data);
  };

  const InitDataEditBanner = async (banner_id) => {
    if (banner_id == 0) {
      setFormData({
        id: 0,
        position: 1,
        category: 0,
        status: 1,
        name: "",
        link: "",
        startDate: props.moment().format("YYYY-MM-DD"),
        endDate: props.moment().add(7, "days").format("YYYY-MM-DD"),
      });

      setHiddenCategory(false);
      setFileList([]);
    } else {
      const banner = await props.handleGetBanner(banner_id);
      setFormData(banner);
      setHiddenCategory(banner.position == 3);
      setSrcImage(process.env.REACT_APP_API_URL + banner.banner);
      setFileList(process.env.REACT_APP_API_URL + banner.banner);
    }
  };

  useImperativeHandle(ref, () => ({
    onSubmit() {
      handleSubmit();
    },
  }));

  useEffect(() => {
    console.log(props.bannerId)
    InitDataEditBanner(props.bannerId);
    getProductCategories();
    setBannerPositions(props.bannerPositions)
  }, [props.bannerId, props.bannerPositions]);

  return (
    <div className="flex gap-5">
      <div className=" p-5 max-w-[900px]">
        <form onSubmit={handleSubmit}>
          <div className="flex gap-5 my-3">
            <div className=" grid grid-cols-2 gap-5">
              <div className="">
                <label for="position">Vị trí</label>
                <div className="flex items-center pr-3">
                  <select
                    id="position"
                    name="position"
                    value={formData.position}
                    onChange={handleChangeValue}
                    className="appearance-none text-[#020202] w-[522px] h-[56px] border-none outline-none text-base px-[25px] py-[px] bg-[#eff0f5] rounded"
                  >
                    {bannerPositions.map((position, i) => {
                      return (
                        <option value={position.id}>
                          {" "}
                          {position.name} ( {position.size} ){" "}
                        </option>
                      );
                    })}
                  </select>
                  <img
                    class="pointer-events-none ml-[-35px] h-6 w-6 "
                    src="../img/icon/arrow-right.png"
                    alt="img"
                  />
                </div>
              </div>
              <div className="">
                {hiddenCategory && (
                  <div>
                    <label for="position">Ngành hàng</label>
                    <div className="flex items-center pr-3">
                      <select
                        id="category"
                        name="category"
                        value={formData.category}
                        onChange={handleChangeValue}
                        className="appearance-none text-[#020202] w-[522px] h-[56px] border-none outline-none text-base px-[25px] py-[px] bg-[#eff0f5] rounded"
                      >
                        {productCategories.map((cate, i) => {
                          return <option value={cate.id}>{cate.name} </option>;
                        })}
                      </select>
                      <img
                        class="pointer-events-none ml-[-35px] h-6 w-6 "
                        src="../img/icon/arrow-right.png"
                        alt="img"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex gap-5 my-3">
            <div className=" grid grid-cols-2 gap-5">
              <div className="w-full">
                <label for="position">Tên banner</label>
                <div className="flex items-center">
                  <input
                    id="name"
                    name="name"
                    type="text"
                    value={formData.name}
                    onChange={handleChangeValue}
                    className="text-[#888888] w-[522px] h-[56px] border-none outline-none text-base px-[25px] py-[20px] bg-[#eff0f5] rounded"
                    placeholder="Tên banner"
                  />
                </div>
              </div>
              <div className="w-full">
                <label for="position">Liên kết</label>
                <div className="flex items-center">
                  <input
                    id="link"
                    name="link"
                    type="text"
                    value={formData.link}
                    onChange={handleChangeValue}
                    className="text-[#888888] w-[522px] h-[56px] border-none outline-none text-base px-[25px] py-[20px] bg-[#eff0f5] rounded"
                    placeholder="Nhập liên kết"
                  />
                </div>
              </div>
              <div className="w-full">
                <label for="status">Trạng thái</label>
                <div className="flex items-center">
                  <Radio.Group
                    id="status"
                    name="status"
                    value={formData.status}
                    onChange={handleChangeValue}
                  >
                    <Radio value={1}>Hoạt động</Radio>
                    <Radio value={0}>Ngưng hoạt động</Radio>
                  </Radio.Group>
                </div>
              </div>
              <div className="w-full">
                <label for="position">Thời gian hoạt động</label>
                <div className="flex items-center">
                  <RangePicker
                    onChange={handleChangeTime}
                    value={[
                      props.dayjs(formData.startDate, dateFormat),
                      props.dayjs(formData.endDate, dateFormat),
                    ]}
                    format={dateFormat}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-5 my-3 justify-center">
            {fileList.length >= 1 && (
              <div
                className=" flex items-center justify-center"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <img
                  src={srcImage}
                  className={isHovered && "opacity-25"}
                  alt="img"
                />
                <div class={isHovered ? " absolute" : "hidden"}>
                  <button
                    onClick={handlePreview}
                    type="button"
                    className="mr-2 bg-slate-400 text-white font-bold py-2 px-4 rounded-full"
                  >
                    <EyeOutlined />
                  </button>
                  <button
                    onClick={handleUpload}
                    type="button"
                    className="mr-2 bg-slate-400 text-white font-bold py-2 px-4 rounded-full"
                  >
                    <RedoOutlined />
                  </button>
                  <button
                    onClick={handleRemoveImg}
                    type="button"
                    className="bg-slate-400 text-white font-bold py-2 px-4 rounded-full"
                  >
                    <DeleteOutlined />
                  </button>
                </div>
              </div>
            )}
            {previewImage && (
              <div>
                <Image
                  wrapperStyle={{
                    display: "none",
                  }}
                  preview={{
                    visible: previewOpen,
                    onVisibleChange: (visible) => setPreviewOpen(visible),
                    afterOpenChange: (visible) =>
                      !visible && setPreviewImage(""),
                  }}
                  src={previewImage}
                  items={previewImage}
                />
              </div>
            )}
            <div className={fileList.length >= 1 && "hidden"}>
              <Dragger
                action={
                  process.env.REACT_APP_API_URL + "/api/banner/uploadImage"
                }
                listType="picture-card"
                className="w-full banner-dragger"
                onChange={handleChange}
                accept="image/*"
                maxCount={1}
                beforeUpload={() => false}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Support for a single or bulk upload. Strictly prohibited from
                  uploading company data or other banned files.
                </p>
              </Dragger>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
});
export default UpsertBanner;
