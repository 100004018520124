import React from "react";

function Footer() {
  return (
    <div className="w-full bg-[#F7F5FF] mt-10">
      <div className="max-w-[1224px] mx-auto py-16 flex justify-between align-center">
        <div className="grid grid-cols-4 gap-10">
          <div>
            <img src="../img/logo.png" className="h-[76px] w-[134px] pl-0" alt="img"/>
            <p className=" font-medium pl-1 text-[#888888] text-sm text-left mt-3">
              Nền tảng mua sắm lý tưởng cho mọi nhu cầu của bạn, với một loạt
              các sản phẩm đa dạng từ nhiều ngành hàng khác nhau. Cùng với, dịch
              vụ giao hàng đúng giờ và chu toàn sẽ mang đến trải nghiệm mua hàng
              tuyệt vời hơn bao giờ hết.
            </p>

            <p className=" font-bold pl-1 text-[#151515] text-base text-left mt-[30px]">
              Chứng nhận bởi
            </p>
            <img
              src="../img/icon/BCT.png"
              className=" mt-5 pl-1 h-[40px] w-[130px]" alt="img"
            />
          </div>
          <div>
            <p className=" font-bold text-[#151515] text-base text-left ">
              Về chúng tôi
            </p>

            <p className=" font-medium text-[#888888] text-sm text-left mt-4">
              Giới thiệu{" "}
              <span className=" font-bold text-[#151515]"> hahashop.vn </span>
            </p>

            <p className=" font-medium  text-[#888888] text-sm text-left mt-2">
              Blog
            </p>

            <p className=" font-bold text-[#151515] text-base text-left mt-12">
              Hợp tác & Liên kết
            </p>

            <p className=" font-medium text-[#888888] text-sm text-left mt-4">
              Bán hàng cùng
              <span className=" font-bold text-[#151515]"> hahashop.vn </span>
            </p>

            <p className=" font-medium text-[#888888] text-sm text-left mt-2">
              Chính sách Đại lý
            </p>
          </div>
          <div>
            <p className=" font-bold text-[#151515] text-base text-left">
              Mua hàng & Vận chuyển
            </p>
            <p className=" font-medium text-[#888888] text-sm text-left mt-4">
              Hướng dẫn đặt hàng
            </p>
            <p className=" font-medium text-[#888888] text-sm text-left mt-2">
              Hướng dẫn thanh toán
            </p>
            <p className=" font-medium text-[#888888] text-sm text-left mt-2">
              Chính sách đổi trả
            </p>
            <p className=" font-medium text-[#888888] text-sm text-left mt-2">
              Chính sách giải quyết khiếu nại
            </p>
            <p className=" font-medium text-[#888888] text-sm text-left mt-2">
              Chính sách giao nhận
            </p>
            <p className=" font-medium text-[#888888] text-sm text-left mt-2">
              Bảo vệ người mua
            </p>
            <p className=" font-medium text-[#888888] text-sm text-left mt-2">
              Hỗ trợ khách hàng: cskh@hahashop.vn
            </p>
            <p className=" font-bold  text-[#151515] text-base text-left mt-[30px]">
              Dịch vụ giao hàng
            </p>
            <img
              src="../img/J&TExpress.png"
              className=" mt-5 h-[40px] w-[146px]" alt="img"
            />
          </div>
          <div>
            <p className=" font-semibold text-[#151515] text-xl text-left">
              Để lại email của bạn để nhận thông báo khuyến mãi
            </p>
            <div className="flex items-center mx-auto mt-4">
              <input
                type="text"
                className="text-[#888888] w-[276px] h-[40px] border-none outline-none text-base px-[25px] py-[15px] bg-[#ffffff] rounded-[44px]"
                placeholder="Email"
              />

              <button className="px-[15px] py-[8px] ml-[-50px] rounded-[52px]">
                <img
                  src="../img/icon/arrow-right-2.png"
                  className="w-[24px] h-[24px]" alt="img"
                />
              </button>
            </div>
            <p className=" font-medium text-[#888888] text-sm text-left mt-3">
              Nhận quyền truy cập độc quyền vào các chương trình giảm giá,
              khuyến mãi và cập nhật mọi thứ về hahashop
            </p>
          </div>
        </div>
      </div>
      <div className="w-full bg-[#6B46FE] py-5 ">
        <div className="max-w-[1224px] border-b-2 mx-auto pb-4 rounded-3 flex justify-between items-center">
          <div>
            <p className=" text-sm font-medium text-white">
              © 2024 hahashop. All Rights Reserved
            </p>
          </div>
          <div className=" flex gap-3">
            <img
              src="../img/social_network/fb.png"
              className="w-[24px] h-[24px]" alt="img"
            />
            <img
              src="../img/social_network/ins.png"
              className="w-[24px] h-[24px]" alt="img"
            />
            <img
              src="../img/social_network/tiktok.png"
              className="w-[24px] h-[24px]" alt="img"
            />
            <img
              src="../img/social_network/youtube.png"
              className="w-[24px] h-[24px]" alt="img"
            />
            <img
              src="../img/social_network/zalo.png"
              className="w-[24px] h-[24px]" alt="img"
            />
          </div>
        </div>
      </div>
      <div className="w-full bg-[#6B46FE] ">
        <div className="max-w-[1224px] mx-auto pb-4 rounded-3 flex justify-center items-center">
          <div className=" flex">
            <p className=" text-sm px-2 font-medium border-r-2 text-white">
              Điều khoản & Điều kiện
            </p>
            <p className=" text-sm  px-2 font-medium text-white border-r-2">
              Quyền sử dụng
            </p>
            <p className=" text-sm  px-2 font-medium text-white">
              Quyền riêng tư
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
