import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./Component/HomePage/HomePage";
import Admin from "./Component/Admin/Admin";
import NotFoundPage from "./Component/NotFoundPage";
import Banner from "./Component/Admin/Banner/Banner";
import Category from "./Component/Admin/Category/Category";
import Register from "./Component/Auth/Register";
import Login from "./Component/Auth/Login";
import PrivateRoute from "./Component/Admin/PrivateRoute";
import BlockLoginRoute from "./Component/Admin/BlockLoginRoute";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />

        <Route element={<BlockLoginRoute />}>
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
        </Route>

        <Route element={<PrivateRoute />}>
          <Route path="/admin" element={<Admin />} />
          <Route path="/admin/banners" element={<Banner />} />
          <Route path="/admin/categories" element={<Category />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}

export default App;
