import React from "react";

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", width:"48px", height:"48px"}}
      onClick={onClick}
    >
    <img src="../img/icon/preArrow.png" className="mr-[15px]"  alt="img"/>
    </div>
  );
}

export default SamplePrevArrow;
