import React, { useState, useEffect } from "react";
import { Carousel } from "antd";
import NextArrow from "../../Arrow/NextArrow";
import PrevArrow from "../../Arrow/PrevArrow";
import ProductCard from "../../Card/ProductCard";

const settings = {
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};
function FlashSaleSection() {
  const calculateTimeLeft = () => {
    const targetDate = "2024-06-30T00:00:00";
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const twoCharacter = (cha) => {
    return cha < 10 ? `0${cha}` : `${cha}`;
  };

  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/api/flash-sale/products"
      );
      const json = await response.json();
      setProducts(json);
    };
    fetchData()
  }, []);

  return (
    <div className="max-w-[1224px] margin-arrow mx-auto my-10 bg-[#FEF6CD] rounded-lg p-10">
      <div className="flex justify-between mx-auto">
        <div className="flex justify-start place-items-end">
          <img
            src="../img/flash-sale.png"
            className="max-h-[111px] max-w-[207px]"
            alt="flash sale"
          />
        </div>
        <div className="grow px-5 max-w-[506px]">
          <p className="text-[600] font-semibold text-left mb-3">
            Thời gian còn lại
          </p>
          <div className="flex justify-center align-items-end bg-[#ffffff] py-6 px-5 rounded-[40px]">
            <div className="grid grid-cols-4 gap-3">
              <div className="grow border-r-2 pr-2">
                <p className="font-semibold text-[#6B46FE] text-sm">
                  <span className="font-extrabold text-[32px] mr-1" id="days">
                    {twoCharacter(timeLeft.days)}
                  </span>
                  <span className="align-super">NGÀY</span>
                </p>
              </div>
              <div className="grow border-r-2 pr-2">
                <p className="font-semibold text-[#6B46FE] text-sm">
                  <span className="font-extrabold text-[32px] mr-1" id="days">
                    {twoCharacter(timeLeft.hours)}
                  </span>
                  <span className="align-super">GIỜ</span>
                </p>
              </div>
              <div className="grow border-r-2 pr-2">
                <p className="font-semibold text-[#6B46FE] text-sm align-text-top">
                  <span className="font-extrabold text-[32px] mr-1" id="days">
                    {twoCharacter(timeLeft.minutes)}
                  </span>
                  <span className="align-super">PHÚT</span>
                </p>
              </div>
              <div className="grow pr-2">
                <p className="font-semibold text-[#6B46FE] text-sm text-start">
                  <span className="font-extrabold text-[32px] mr-1" id="days">
                    {twoCharacter(timeLeft.seconds)}
                  </span>
                  <span className="align-super">GIÂY</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end pb-1 place-items-end">
          <button className="bg-[#6B46FE] rounded-[48px] max-h-[48px] border-none px-6 py-3">
            <a className="text-[#ffffff]">Xem tất cả</a>
          </button>
        </div>
      </div>
      <Carousel
        arrows
        infinite={true}
        {...settings}
        className="max-w-[1144px] mt-10"
      >
        {products.map((pro, i) => {
          return (
            <div>
              <div className="grid grid-cols-4 gap-4">
                {pro.map((p, i) => {
                  return <ProductCard product={p}></ProductCard>;
                })}
              </div>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
}

export default FlashSaleSection;
