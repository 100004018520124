import React from "react";
import BlogCard from "../../Card/BlogCard";

function BlogSection() {
  return (
    <div className="max-w-[1224px] mt-[80px] mx-auto rounded-3">
      <div className="flex justify-between">
        <div className="flex justify-start items-center">
        <img
            src="../img/logo.png"
            className="mr-2 h-[44px] w-[91px] bg-[#6b46fe] rounded-[32px]"
            alt="Logo"
          />
          <span className="mb-0 text-[40px] text-[#151515] font-bold">
            Blog
          </span>
        </div>
        <button className="bg-[#6B46FE] rounded-[48px] max-h-[48px] border-none px-6 py-3">
          <a className="text-[#ffffff]">Xem tất cả</a>
        </button>
      </div>

      <div className="grid grid-cols-3 gap-4 mt-8">
        <div className="row-span-3">
          <BlogCard height="560px"/>
        </div>
        <BlogCard height="184px"/>
        <BlogCard height="184px"/>
        <BlogCard height="184px"/>
        <BlogCard height="184px"/>
      </div>
    </div>
  );
}

export default BlogSection;
