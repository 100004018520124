import React from "react";

const SupportCenter = () => {
  return (
    <div className="fixed bottom-4 right-4">
      <img src="../img/icon/suppot_center.png" alt="img" className="h-[134px] w-[153px]" />
    </div>
  );
};

export default SupportCenter;
