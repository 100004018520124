import axios from "axios";

const apiToken = (baseURL) => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL + "/api" + baseURL,
    withCredentials: true,
  });

  let refreshQueue = [];

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          await instance.post(process.env.REACT_APP_API_URL + "/api/refresh");
          refreshQueue.forEach((prom) => {
            prom.resolve("token");
          });
          refreshQueue = [];
          return instance(originalRequest);
        } catch (err) {
          console.error("Refresh token failed", err);
        }
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

export default apiToken;
