import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

function FormLogin(props) {
  const [isShowPass, setIsShowPass] = useState(false);
  const [formData, setFormData] = useState({
    phone_number: "",
    password: "",
  });

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onShowPass = () => {
    setIsShowPass(!isShowPass);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    props.submitForm(formData);
  };
  useEffect(() => {
  }, []);
  
  return (
    <form onSubmit={onSubmit}>
      <div className="w-[496px] 2xl:mt-[30px] xl-[1366px]:mt-[10px] mt-[48px] mb-2">
        <div className="w-72 mt-[30px]">
          <div className="relative w-[500px] min-w-[200px] h-[58px]">
            <input
              name="phone_number"
              value={formData.phone_number}
              type="number"
              onChange={onChange}
              className={
                props.classNameInput +
                ` [&::-webkit-inner-spin-button]:appearance-none`
              }
            />
            <label className=" flex w-full h-full select-none pointer-events-none absolute left-4 leading-tight top-1.5 text-[#D9D9D9] text-[13px]">
              Số điện thoại
            </label>
          </div>
        </div>
        <div className="w-72 mt-[30px]">
          <div className="relative w-[500px] min-w-[200px] h-[58px]">
            <div className="absolute grid w-5 h-5 place-items-center text-blue-gray-500 top-2/4 right-3 -translate-y-2/4">
              {isShowPass ? (
                <EyeInvisibleOutlined onClick={onShowPass} />
              ) : (
                <EyeOutlined onClick={onShowPass} />
              )}
            </div>
            <input
              name="password"
              type={isShowPass ? "text" : "password"}
              value={formData.password}
              className={props.classNameInput}
              onChange={onChange}
            />
            <label className=" flex w-full h-full select-none pointer-events-none absolute left-4 leading-tight top-1.5 text-[#D9D9D9] text-[13px]">
              Mật khẩu
            </label>
          </div>
        </div>
        <div className=" flex justify-center text-sm gap-2 text-[#515151] mt-[30px]">
          <button className="bg-[#6B46FE] rounded-[48px] max-h-[48px] border-none px-6 py-3">
            <a className="text-[#ffffff]">Đăng nhập</a>
          </button>
        </div>
      </div>
    </form>
  );
}

export default FormLogin;
