import React from "react";

function Header() {
  return (
    <div className="bg-[#6B46FE] text-sm py-[10px] w-full ">
      <div className="max-w-[1224px] mx-auto flex justify-between items-center text-white text-sm">
        <div className="flex gap-5 items-center">
          <div className="flex">
            <img src="../img/icon/call.png" className="h-5 w-5"  alt="img"/>
            &nbsp;19001099
          </div>
          <div className="flex">
            <img src="../img/icon/sms.png"  alt="img"/>
            &nbsp;cskh@hahashop.vn
          </div>
          <div className="flex">
            <img src="../img/icon/headphone.png"  alt="img"/>
            &nbsp;Hỗ trợ khách hàng
          </div>
        </div>
        <div className="flex gap-5 items-center">
          <a href="#" className="flex">
            <img
              src="../img/icon/icon have badge.png"
              className="h-[20px] w-[20px]" alt="img"
            />{" "}
            &nbsp;Thông báo
            <span className="absolute top-1 ml-2 align-super inline-flex items-center border-white border justify-center px-[4px] py-[2px] text-xs leading-none text-white bg-[#FE5757] rounded-full">
              3
            </span>
          </a>
          <a href="#" className="flex">
            <img src="../img/icon/truck-fast.png" className="h-[20px] w-[20px]"  alt="img"/>{" "}
            &nbsp;Theo dõi đơn hàng
          </a>
          <img src="../img/language button.png" className="h-[32px] w-[40px]"  alt="img"/>
        </div>
      </div>
    </div>
  );
}

export default Header;
