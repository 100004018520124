import React from "react";

function BrandSaleCard(props) {

  var classImg = `rounded-t-lg object-cover h-[${props.height}]`;
  
  return (
    <div className="p-0 mb-3">
      <img
        src="https://via.placeholder.com/800x450"
        className= {classImg}
        alt="img"
      />
      <div className="grid grid-cols-11 py-0">
        <div className="col-span-3 py-3 text-center col-3 bg-[#e8e3ff] rounded-bl-lg">
          <p className="mb-2 font-bold text-2xl text-[#151515]">
            -20%
          </p>
          <p className="p2 pb-2 font-medium text-[#515151] text-xs">
            Còn 2 ngày
          </p>
        </div>
        <div className="col-span-8 py-2 pl-4 text-left items-center bg-[#F8F8F8] rounded-br-lg">
          <p className="mt-2 my-2 text-[#151515] text-base font-semibold truncate">
            Giảm đến -50% - Tất cả các thiết bị công nghệ
          </p>
          <span className="text-[#888888] font-medium text-sm">
            Chỉ từ&nbsp;
            <span className="text-[#151515] ml-0 font-semibold">
              1.000.000đ
            </span>
          </span>
        </div>
      </div>
    </div>
  );
}

export default BrandSaleCard;
