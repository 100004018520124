import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { CartContext } from "../Context/CartContext";
import { useNavigate } from "react-router-dom";
import apiToken from "../../Auth/apiToken";

function SearchBar() {
  const [address, setAddress] = useState("Nhà");
  const [error, setError] = useState(null);
  const [user, setUser] = useState(false);
  const { totalItems } = useContext(CartContext);
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  const handleSuccess = (position) => {
    const { latitude, longitude } = position.coords;
    fetchAddress(latitude, longitude);
  };
  const logOut = () => {
    navigate("/login");
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("user");
    setUser(false);
  };

  const handleError = (error) => {
    setError(error.message);
  };

  const fetchAddress = async (latitude, longitude) => {
    const url = `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`;

    try {
      const response = await axios.get(url);
      if (response.data.address.city.length > 0) {
        setAddress(response.data.address.city);
      } else {
        setError("No address found");
      }
    } catch (error) {
      setError("Failed to fetch address");
    }
  };

  const getUserLogin = async () => {
    try {
      const response = await apiToken("").post("/getUserLogin");
      setUser(response.data.user);
    } catch (error) {
      setError("Failed to fetch address");
    }
  };

  useEffect(() => {
    getUserLogin();

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  }, []);

  return (
    <header className="bg-[#fff] sticky top-0 z-50">
      <div className=" text-sm py-5 mb-5 w-full border-b-[1px] border-solid-[#D9D9D9]">
        <div className="max-w-[1224px] mx-auto flex justify-start items-center text-[#515151]">
          <div className="mr-4">
            <img
              src="../img/logo.png"
              className="max-h-[56px] max-w-[112px]"
              alt="Logo"
            />
          </div>
          <div
            className="text-[#515151] flex mx-auto text-base font-bold"
            // onMouseEnter={() => setIsHovered(true)}
          >
            <img src="../img/icon/menu.png" className="mr-[15px]" alt="img" />
            <span> Danh mục</span>
          </div>
          <div className="flex items-center mx-auto">
            <input
              type="text"
              className="text-[#888888] w-[522px] h-[56px] border-none outline-none text-base px-[25px] py-[20px] bg-[#eff0f5] rounded-[56px]"
              placeholder="Giao nhanh 2h & đúng khung giờ"
            />
            <button className="px-[32px] py-[12px] bg-gradient-to-r ml-[-92px] from-[#807EF5] to-[#6484F6] rounded-[52px]">
              <img src="../img/icon/search-normal.png" alt="img" />
            </button>
          </div>
          <div className="flex items-center mx-3 ">
            <img
              src="../img/icon/user-square.png"
              className="mr-2 h-[32px] w-[32px] cursor-pointer"
              alt="img"
              onClick={() => logOut()}
            />
            {user !== false ? (
              <div className="flex-grow-1 ms-3">
                <a className="text-[#888888] text-[13px]">Xin Chào</a>
                <p className="text-base font-semibold text-[#515151]">
                  {user.name}
                </p>
              </div>
            ) : (
              <a className="text-base font-semibold text-[#515151]">
                Tài khoản
              </a>
            )}
          </div>
          <div className="flex items-center mx-3 mr-6 text-start">
            <div className="flex-shrink-0">
              <img
                src="../img/icon/location.png"
                className=" h-[32px] w-[32px]"
                alt="img"
              />
            </div>
            <div className="flex-grow-1 ms-3">
              <a className="text-[#888888] text-[13px]">Giao tại</a>
              <p className="text-base font-semibold text-[#515151]">
                {address}
              </p>
            </div>
          </div>
          <div className="flex items-center mx-3 ">
            <img
              src="../img/icon/shopping-cart.png"
              alt="img"
              className=" h-[32px] w-[32px]"
            />
            <span className="absolute top-6 ml-6 align-super inline-flex items-center border-white border justify-center px-[4px] py-[2px] text-xs leading-none text-white bg-[#FE5757] rounded-full">
              {totalItems}
            </span>
          </div>
        </div>
      </div>
      <div
        className="max-w-[1224px] mx-auto sticky"
        // onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {isHovered && (
          <div className=" w-full grid grid-cols-4 gap-4 absolute ">
            <div className="justify-center items-center bg-white py-4 rounded-xl ">
              <div className="flex justify-between px-4 text-[#041944] items-center hover:bg-[#F7F5FF] hover:text-[#6B46FE]">
                <p className="py-2 font-semibold text-base">Thế giới trà đạo</p>
                <img
                  src="../img/icon/arrow-right.png"
                  className="h-[24px] w-[24px]"
                  alt="arrow-right"
                />
              </div>
              <div className="flex justify-between px-4 text-[#041944] items-center hover:bg-[#F7F5FF] hover:text-[#6B46FE]">
                <p className="py-2 font-semibold text-base">Thế giới trà đạo</p>
                <img
                  src="../img/icon/arrow-right.png"
                  className="h-[24px] w-[24px]"
                  alt="arrow-right"
                />
              </div>
              <div className="flex justify-between px-4 text-[#041944] items-center hover:bg-[#F7F5FF] hover:text-[#6B46FE]">
                <p className="py-2 font-semibold text-base">Thế giới trà đạo</p>
                <img
                  src="../img/icon/arrow-right.png"
                  className="h-[24px] w-[24px]"
                  alt="arrow-right"
                />
              </div>
              <div className="flex justify-between px-4 text-[#041944] items-center hover:bg-[#F7F5FF] hover:text-[#6B46FE]">
                <p className="py-2 font-semibold text-base">Thế giới trà đạo</p>
                <img
                  src="../img/icon/arrow-right.png"
                  className="h-[24px] w-[24px]"
                  alt="arrow-right"
                />
              </div>
              <div className="flex justify-between px-4 text-[#041944] items-center hover:bg-[#F7F5FF] hover:text-[#6B46FE]">
                <p className="py-2 font-semibold text-base">Thế giới trà đạo</p>
                <img
                  src="../img/icon/arrow-right.png"
                  className="h-[24px] w-[24px]"
                  alt="arrow-right"
                />
              </div>
            </div>
            <div className="col-span-3  flex justify-start items-center bg-white rounded-lg">
              <div className=" w-full grid grid-cols-4 gap-3 mt-4 justify-center items-center  rounded-lg  p-6">
                <div className=" p-4 text-[#041944] items-center hover:bg-[#F7F5FF] hover:rounded-lg">
                  <div className="flex justify-center">
                    <img
                      src="../img/productMenuCategory.png"
                      className="h-[64px] w-[64px] flex-grow-1"
                      alt="arrow-right"
                    />
                  </div>

                  <p className="py-2 font-semibold text-base text-center">
                    Thế giới trà đạo
                  </p>
                </div>
                <div className=" p-4 text-[#041944] items-center hover:bg-[#F7F5FF] hover:rounded-lg">
                  <div className="flex justify-center">
                    <img
                      src="../img/productMenuCategory.png"
                      className="h-[64px] w-[64px] flex-grow-1"
                      alt="arrow-right"
                    />
                  </div>

                  <p className="py-2 font-semibold text-base text-center">
                    Thế giới trà đạo
                  </p>
                </div>
                <div className=" p-4 text-[#041944] items-center hover:bg-[#F7F5FF] hover:rounded-lg">
                  <div className="flex justify-center">
                    <img
                      src="../img/productMenuCategory.png"
                      className="h-[64px] w-[64px] flex-grow-1"
                      alt="arrow-right"
                    />
                  </div>

                  <p className="py-2 font-semibold text-base text-center">
                    Thế giới trà đạo
                  </p>
                </div>
                <div className=" p-4 text-[#041944] items-center hover:bg-[#F7F5FF] hover:rounded-lg">
                  <div className="flex justify-center">
                    <img
                      src="../img/productMenuCategory.png"
                      className="h-[64px] w-[64px] flex-grow-1"
                      alt="arrow-right"
                    />
                  </div>

                  <p className="py-2 font-semibold text-base text-center">
                    Thế giới trà đạo
                  </p>
                </div>
                <div className=" p-4 text-[#041944] items-center hover:bg-[#F7F5FF] hover:rounded-lg">
                  <div className="flex justify-center">
                    <img
                      src="../img/productMenuCategory.png"
                      className="h-[64px] w-[64px] flex-grow-1"
                      alt="arrow-right"
                    />
                  </div>

                  <p className="py-2 font-semibold text-base text-center">
                    Thế giới trà đạo
                  </p>
                </div>
                <div className=" p-4 text-[#041944] items-center hover:bg-[#F7F5FF] hover:rounded-lg">
                  <div className="flex justify-center">
                    <img
                      src="../img/productMenuCategory.png"
                      className="h-[64px] w-[64px] flex-grow-1"
                      alt="arrow-right"
                    />
                  </div>

                  <p className="py-2 font-semibold text-base text-center">
                    Thế giới trà đạo
                  </p>
                </div>
                <div className=" p-4 text-[#041944] items-center hover:bg-[#F7F5FF] hover:rounded-lg">
                  <div className="flex justify-center">
                    <img
                      src="../img/productMenuCategory.png"
                      className="h-[64px] w-[64px] flex-grow-1"
                      alt="arrow-right"
                    />
                  </div>

                  <p className="py-2 font-semibold text-base text-center">
                    Thế giới trà đạo
                  </p>
                </div>
                <div className=" p-4 text-[#041944] items-center hover:bg-[#F7F5FF] hover:rounded-lg">
                  <div className="flex justify-center">
                    <img
                      src="../img/productMenuCategory.png"
                      className="h-[64px] w-[64px] flex-grow-1"
                      alt="arrow-right"
                    />
                  </div>

                  <p className="py-2 font-semibold text-base text-center">
                    Thế giới trà đạo
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </header>
  );
}

export default SearchBar;
