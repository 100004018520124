import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import {
  DeleteOutlined,
  UnorderedListOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import { Checkbox, Image, Modal } from "antd";
import UpsertCategory from "./UpsertCategory";

const LayoutCategory = (props) => {
  const { confirm } = Modal;
  const [categories, setCategories] = useState([]);
  const [categoriesChecked, setCategoriesChecked] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [totalCategories, setTotalCategories] = useState(false);
  const [categoryId, setCategoryId] = useState(0);
  const [formCategoryData, setFormCategoryData] = useState({
    name: "",
    status: 2,
  });
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModal = (cateId) => {
    setIsModalOpen(true);
    setCategoryId(cateId);
  };

  const showDeleteConfirm = () => {
    confirm({
      title: "Bạn chắc chắn muốn xóa Danh mục này?",
      icon: <ExclamationCircleFilled />,
      okText: "Xác nhận",
      okType: "danger",
      cancelText: "Hủy",
      onOk() {
        handleDeleteCategories();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleSetTotalCategories = (totalCategories) => {
    let total = totalCategories.reduce((acc, obj) => {
      acc++;
      obj.dependent.forEach(() => {
        acc++;
      });
      return acc;
    }, 0);

    setTotalCategories(total);
  };

  const getCategories = async (e) => {
    try {
      const response = await props.props.handleGetAllCategories(
        formCategoryData
      );
      setCategories(response.data);
      handleSetTotalCategories(response.data);
      setCategoriesChecked([]);
    } catch (error) {
      toast.error("Có lỗi xảy ra, vui lòng thử lại sau!");
    }
  };

  const onChangeCheckBoxAll = (e) => {
    if (e.target.checked) {
      let combinedIds = [];

      categories.forEach((cateLV1) => {
        combinedIds.push(cateLV1.id);

        cateLV1.dependent.forEach((cateLV2) => {
          combinedIds.push(cateLV2.id);
        });
      });
      setCategoriesChecked(combinedIds);
    } else {
      setCategoriesChecked([]);
    }
  };

  const onChangeCheckBox = (e) => {
    if (e.target.checked) {
      setCategoriesChecked([...categoriesChecked, e.target.value]);
    } else {
      setCategoriesChecked(
        categoriesChecked.filter((item) => item !== e.target.value)
      );
    }
  };

  const handleUpdateStatusAll = async (status) => {
    const statusRes = await props.props.handleUpdateStatusAll(
      status,
      categoriesChecked
    );
    statusRes === "OK"
      ? toast.success("Cập nhật thành công!")
      : toast.error("Cập nhật thất bại!");
    getCategories();
  };

  const handleDeleteCategories = async () => {
    const statusRes = await props.props.handleDeleteCategories(
      categoriesChecked
    );
    statusRes === "OK"
      ? toast.success("Xóa thành công!")
      : toast.error("Xóa thất bại!");
    setCategoriesChecked([]);
    getCategories();
  };

  const handleChangeFormCategoryValue = (e) => {
    const { name, value } = e.target;
    setFormCategoryData({ ...formCategoryData, [name]: value });
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <div className=" px-5 mt-7">
      <div className=" grid grid-cols-7 gap-4">
        <div className="w-full col-span-3">
          <label for="name">Tên Danh mục</label>
          <div className="flex items-center">
            <input
              id="name"
              name="name"
              type="text"
              onChange={handleChangeFormCategoryValue}
              value={formCategoryData.name}
              className="text-[#888888] w-full h-[45px] border-2 outline-none text-base px-[25px] py-[20px] rounded"
              placeholder="Tên Danh mục"
            />
          </div>
        </div>
        <div className="w-full col-span-2">
          <label for="status">Trạng thái</label>
          <div className="flex items-center">
            <select
              id="status"
              name="status"
              onChange={handleChangeFormCategoryValue}
              value={formCategoryData.status}
              className="appearance-none text-[#020202] w-full h-[45px] border-2 outline-none text-base px-[15px] rounded"
            >
              <option value={2}> Tất cả</option>
              <option value={1}> Hoạt động</option>
              <option value={0}> Ngừng hoạt động</option>
            </select>
            <img
              className="pointer-events-none ml-[-35px] h-6 w-6 "
              src="../img/icon/arrow-right.png"
              alt=""
            />
          </div>
        </div>
        <div className="w-full flex items-end">
          <div className="flex w-full flex-col justify-end">
            <button
              type="button"
              onClick={getCategories}
              className="mr-2 bg-red-600 w-full text-white font-bold py-2 px-4 border-none rounded h-[45px]"
            >
              Tìm kiếm
            </button>
          </div>
        </div>
      </div>
      <div className=" flex justify-end mt-5">
        <button
          type="button"
          onClick={() => showModal(0)}
          className=" border-2 rounded-md py-2 px-5 border-blue-400 bg-white text-blue-400"
        >
          Thêm mới Danh mục
        </button>
      </div>

      {categoriesChecked.length > 0 ? (
        <div className="gap-4 mt-5 py-1 border-y-[4px] px-12 flex justify-start items-center">
          <Checkbox
            onChange={onChangeCheckBoxAll}
            checked={categoriesChecked.length === totalCategories}
          >
            Có {categoriesChecked.length} danh mục đang được chọn
          </Checkbox>
          <button
            type="button"
            onClick={() => handleUpdateStatusAll(1)}
            className="mr-2 bg-green-100  text-green-600 py-2 px-4 border-none rounded-lg h-[44px]"
          >
            Hoạt động
          </button>
          <button
            type="button"
            onClick={() => handleUpdateStatusAll(0)}
            className="mr-2 bg-red-100  text-red-600 py-2 px-4 border-none rounded-lg h-[44px]"
          >
            Ngưng hoạt động
          </button>
          <button
            type="button"
            onClick={showDeleteConfirm}
            className="mr-2 bg-red-100  text-red-600 py-2 px-4 border-none rounded-lg h-[44px]"
          >
            <DeleteOutlined className=" text-xl text-red-600" />
            &nbsp;Xóa
          </button>
        </div>
      ) : (
        <div className="grid grid-cols-7 gap-4 mt-5 border-y-[4px] py-4">
          <div className="w-full col-span-3 flex justify-center">
            <label for="position">Danh mục</label>
          </div>
          <div className="w-full  flex justify-center">
            <label for="position">Hình ảnh</label>
          </div>
          <div className="w-full  flex justify-center">
            <label for="position">Sản phẩm</label>
          </div>
          <div className="w-full col-span-2 flex justify-center">
            <label for="position">Trạng thái</label>
          </div>
        </div>
      )}

      {categories.map((cate1, i1) => {
        return (
          <div>
            <div className="grid grid-cols-7 gap-4 mt-2 py-4">
              <div className="w-full items-center col-span-3 flex justify-start pl-32">
                <label for="position" className=" flex items-center">
                  <UnorderedListOutlined />
                  &nbsp;
                  <Checkbox
                    onChange={onChangeCheckBox}
                    value={cate1.id}
                    checked={categoriesChecked.indexOf(cate1.id) !== -1}
                  />
                  <p
                    className=" text-blue-500 cursor-pointer"
                    onClick={() => showModal(cate1.id)}
                  >
                    &nbsp;
                    {" " + (i1 + 1) + " " + cate1.name}
                  </p>
                </label>
              </div>
              <div className="w-full items-center flex justify-center">
                <label for="position">
                  <div className="flex items-center mt-4 cursor-pointer">
                    <Image
                      preview={{
                        mask: null,
                      }}
                      src={process.env.REACT_APP_API_URL + cate1.source}
                      className="max-h-[40px] max-w-[90px]"
                      alt="Hình ảnh Danh mục"
                    />
                  </div>
                </label>
              </div>
              <div className="w-full items-center flex justify-center">
                <label for="position">10</label>
              </div>
              <div className="w-full col-span-2 flex justify-center items-center">
                <label
                  for="position"
                  className={cate1.status ? "text-green-600" : "text-red-600"}
                >
                  {cate1.status ? "Hoạt động" : "Không hoạt động"}
                </label>
              </div>
            </div>
            {cate1.dependent.map((cate2, i2) => {
              return (
                <div className="grid grid-cols-7 gap-4 mt-2 py-4">
                  <div className="w-full ml-10 col-span-3 flex items-center justify-start pl-40">
                    <label for="position" className=" flex items-center">
                      <UnorderedListOutlined />
                      &nbsp;
                      <Checkbox
                        onChange={onChangeCheckBox}
                        value={cate2.id}
                        checked={categoriesChecked.indexOf(cate2.id) !== -1}
                      />
                      &nbsp;
                      <p
                        className=" cursor-pointer"
                        onClick={() => showModal(cate2.id)}
                      >
                        {" " + (i1 + 1) + "." + (i2 + 1) + " " + cate2.name}
                      </p>
                    </label>
                  </div>
                  <div className="w-full flex justify-center items-center">
                    <label for="position">
                      <div
                        className="flex items-center mt-4"
                        onClick={() => showModal(cate2.id)}
                      >
                        <Image
                          preview={{
                            mask: null,
                          }}
                          src={process.env.REACT_APP_API_URL + cate2.source}
                          className="max-h-[40px] max-w-[90px] cursor-pointer"
                          alt="Hình ảnh Danh mục"
                        />
                      </div>
                    </label>
                  </div>
                  <div className="w-full flex justify-center items-center">
                    <label for="position">10</label>
                  </div>
                  <div className="w-full col-span-2 flex justify-center items-center">
                    <label
                      for="position"
                      className={
                        cate2.status ? "text-green-600" : "text-red-600"
                      }
                    >
                      {cate2.status ? "Hoạt động" : "Không hoạt động"}
                    </label>
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
      <Modal
        width={1400}
        open={isModalOpen}
        afterClose={getCategories}
        onCancel={handleCancel}
        footer={false}
      >
        <UpsertCategory
          categoryId={categoryId}
          categories={categories}
          handleCancel={handleCancel}
          getCategories={getCategories}
          handleGetCategory={props.props.handleGetCategory}
          handleCreateCategory={props.props.handleCreateCategory}
        />
      </Modal>
      <ToastContainer />
    </div>
  );
};
export default LayoutCategory;
