import React from "react";
import Menu from "../Menu";
import apiToken from "../../Auth/apiToken";

const Category = () => {
  const title = "Quản lý Danh mục";
  const customApi = apiToken("/admin/products-categories");

  const getCategories = async (formCategoryData) => {
    return await customApi.post("/searchCategories", formCategoryData);
  };

  const handleUpdateStatusAll = async (status, categoriesIds) => {
    const response = await customApi.post("/updateStatusCategories", {
      status: status,
      ids: categoriesIds,
    });
    return response.statusText;
  };

  const handleDeleteCategories = async (categoriesIds) => {
    const response = await customApi.post("/deleteCategories", {
      ids: categoriesIds,
    });
    return response.statusText;
  };
  
  const handleGetCategory = async (categoryId) => {
    const response = await customApi.get("/getCategory/" + categoryId);
    return {
      id: response.data.id,
      name: response.data.name,
      status: response.data.status,
      level: response.data.level,
      dependent: response.data.dependent,
      source: response.data.source,
      description: response.data.description,
      title: response.data.seo.title || "",
      alias: response.data.seo.alias || "",
      description_seo: response.data.seo.description_seo || "",
    };
  };
  
  const handleCreateCategory = async (formCategoryData) => {
    const response = await customApi.post("/createCategory", formCategoryData);
    return response.statusText;
  };

  return (
    <Menu
      title={title}
      isCategory={true}
      handleGetAllCategories={getCategories}
      handleUpdateStatusAll={handleUpdateStatusAll}
      handleDeleteCategories={handleDeleteCategories}
      handleGetCategory={handleGetCategory}
      handleCreateCategory={handleCreateCategory}
    />
  );
};
export default Category;
