import React, { useState, useEffect } from "react";
import { Outlet, Route } from "react-router-dom";
import NotFoundPage from "../NotFoundPage";
import apiToken from "../Auth/apiToken";
import { useNavigate } from "react-router-dom";

const PrivateRoute = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [userLogin, setUserLogin] = useState({});
  const navigate = useNavigate();
  const getUserLogin = async () => {
    try {
      const response = await apiToken("").post("/getUserLogin");
      setIsAdmin(response.data.isAdmin);
      setIsLogin(true);
      setUserLogin(response.data.userLogin);
    } catch (error) {
      console.log("Failed to fetch address");
    }
  };

  useEffect(() => {
    getUserLogin();
  }, []);

  if (!isLogin) {
    return navigate("/login");
  } else {
    return isAdmin ? <Outlet user={userLogin} /> : <NotFoundPage />;
  }
};

export default PrivateRoute;
