import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

function FormRegister(props) {
  const [isShowPass, setIsShowPass] = useState(false);
  const [errorsRes, setErrorsRes] = useState({});
  const [errors, setErrors] = useState({
    validation: false,
  });
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    password: "",
  });

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (e.target.name === "password") {
      setErrors(validatePassword(e.target.value));
    }
  };

  const onShowPass = () => {
    setIsShowPass(!isShowPass);
  };

  useEffect(() => {
    setErrorsRes(props.errorsRes)
  }, [props.errorsRes]);

  const validatePassword = (password) => {
    let errors = {
      validation: true,
      lengthError: true,
      lowercaseError: true,
      uppercaseError: true,
      numberError: true,
      specialError: true,
    };

    if (password.length < 8 || password.length > 25) {
      errors = { ...errors, validation: false, lengthError: false };
    }
    if (!/[a-z]/.test(password)) {
      errors = { ...errors, validation: false, lowercaseError: false };
    }
    if (!/[A-Z]/.test(password)) {
      errors = { ...errors, validation: false, uppercaseError: false };
    }
    if (!/[0-9]/.test(password)) {
      errors = { ...errors, validation: false, numberError: false };
    }
    if (!/[@$!%*#?&]/.test(password)) {
      errors = { ...errors, validation: false, specialError: false };
    }
    return errors;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!errors.validation) {
      return toast.error("Mật khẩu không thỏa mãn các điều kiện!");
    }
    props.submitForm(formData);
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="w-[496px] 2xl:mt-[30px] xl-[1366px]:mt-[10px] mt-[48px] mb-2">
        <div className="relative w-[500px] min-w-[200px] h-[58px]">
          <input
            name="name"
            value={formData.name}
            onChange={onChange}
            className={
              props.classNameInput + (errorsRes.name && ` border-red-400 border-2`)
            }
          />
          <label className=" flex w-full h-full select-none pointer-events-none absolute left-4 leading-tight top-1.5 text-[#D9D9D9] text-[13px]">
            Họ & Tên
          </label>
        </div>
        <div className="w-72 mt-[30px]">
          <div className="relative w-[500px] min-w-[200px] h-[58px]">
            <input
              name="phone"
              value={formData.phone}
              type="number"
              onChange={onChange}
              className={
                props.classNameInput +
                (errorsRes.phone && ` border-red-400 border-2`) +
                ` [&::-webkit-inner-spin-button]:appearance-none`
              }
            />
            {errorsRes.phone && (
              <div className="error text-[#FE5757] text-xs">
                {errorsRes.phone[0]}
              </div>
            )}
            <label className=" flex w-full h-full select-none pointer-events-none absolute left-4 leading-tight top-1.5 text-[#D9D9D9] text-[13px]">
              Số điện thoại
            </label>
          </div>
        </div>
        <div className="w-72 mt-[30px]">
          <div className="relative w-[500px] min-w-[200px] h-[58px]">
            <input
              name="email"
              type="email"
              value={formData.email}
              onChange={onChange}
              className={
                props.classNameInput + (errorsRes.email && ` border-red-400 border-2`)
              }
            />
            {errorsRes.email && (
              <div className="error text-[#FE5757] text-xs">
                {errorsRes.email[0]}
              </div>
            )}
            <label className=" flex w-full h-full select-none pointer-events-none absolute left-4 leading-tight top-1.5 text-[#D9D9D9] text-[13px]">
              Email
            </label>
          </div>
        </div>
        <div className="w-72 mt-[30px]">
          <div className="relative w-[500px] min-w-[200px] h-[58px]">
            <div className="absolute grid w-5 h-5 place-items-center text-blue-gray-500 top-2/4 right-3 -translate-y-2/4">
              {isShowPass ? (
                <EyeInvisibleOutlined onClick={onShowPass} />
              ) : (
                <EyeOutlined onClick={onShowPass} />
              )}
            </div>
            <input
              name="password"
              type={isShowPass ? "text" : "password"}
              value={formData.password}
              className={
                props.classNameInput + (errorsRes.email && ` border-red-400 border-2`)
              }
              onChange={onChange}
            />
            <label className=" flex w-full h-full select-none pointer-events-none absolute left-4 leading-tight top-1.5 text-[#D9D9D9] text-[13px]">
              Mật khẩu
            </label>
          </div>
        </div>
        <div className=" text-sm gap-2 text-[#515151]">
          <p className=" my-5 font-semibold text-[#151515]">
            Yêu cầu mật khẩu cần chứa
          </p>
          <ul className="list-disc list-inside grid grid-cols-2 gap-4">
            <li className={errors.lengthError && `text-green-600`}>
              Tối thiểu 8 ký tự, tối đa 25 ký tự
            </li>
            <li className={errors.numberError && `text-green-600`}>Số</li>
            <li className={errors.lowercaseError && `text-green-600`}>
              Chữ cái viết thường
            </li>
            <li className={errors.specialError && `text-green-600`}>
              Ký tự đặc biệt
            </li>
            <li className={errors.uppercaseError && `text-green-600`}>
              Chữ cái viết hoa
            </li>
          </ul>
          <div className=" flex justify-center">
            <button className="bg-[#6B46FE] rounded-[48px] max-h-[48px] border-none px-6 py-3">
              <a className="text-[#ffffff]">Đăng ký</a>
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

export default FormRegister;
