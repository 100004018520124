import React from "react";

function BenefitSection() {
  return (
    <div className="max-w-[1224px] mx-auto rounded-3 flex justify-center align-center">
      <div className="flex align-center justify-center rounded-[12px] bg-[#F8F8F8]">
        <div className="p-5 grow flex align-center justify-center">
          <div className="grid grid-rows-2 grid-flow-col border-r-2 pr-5  h-[80px]">
            <div className="row-span-2">
              <img className="pr-1 mr-2" src="../img/icon/box-tick.png" />
            </div>
            <div className="col-span-2 ">
              <p className="text-left text-base font-bold text-[#151515]">Cam kết chất lượng</p>
            </div>
            <div className="col-span-2 text-left">
              <span className=" text-base font-[500] text-[#515151]">Hàng hóa đảm bảo nguồn gốc rõ ràng</span>
            </div>
          </div>
          <div className="grid pl-4 grid-rows-2 grid-flow-col border-r-2 h-[80px]">
            <div className="row-span-2">
            <img className="pr-1 mr-2" src="../img/icon/percentage-circle.png" />
            </div>
            <div className="col-span-2 ">
              <p className="text-left text-base font-bold text-[#151515]">Ưu đãi hấp dẫn</p>
            </div>
            <div className="col-span-2 text-left">
              <span className=" text-base font-[500] text-[#515151]">Vào các dịp lễ và ngày đặc biệt</span>
            </div>
          </div>
          <div className="grid pl-4 grid-rows-2 grid-flow-col border-r-2 h-[80px]">
            <div className="row-span-2">
            <img className="pr-1 mr-2" src="../img/icon/group.png" />
            </div>
            <div className="col-span-2 ">
              <p className="text-left text-base font-bold text-[#151515]">Giao hàng đúng hẹn</p>
            </div>
            <div className="col-span-2 text-left">
              <span className=" text-base font-[500] text-[#515151]">Cam kết giao đúng giờ, nhận chu toàn</span>
            </div>
          </div>
          <div className="grid pl-4 grid-rows-2 grid-flow-col border-r-2 h-[80px]">
            <div className="row-span-2">
            <img className="pr-1 mr-2" src="../img/icon/card-pos.png" />
            </div>
            <div className="col-span-2 ">
              <p className="text-left text-base font-bold text-[#151515]">Thanh toán tiện lợi</p>
            </div>
            <div className="col-span-2 text-left">
              <span className=" text-base font-[500] text-[#515151]">Đa dạng phương thức, bảo mật tối đa</span>
            </div>
          </div>
          <div className="grid pl-4 grid-rows-2 grid-flow-col h-[80px]">
            <div className="row-span-2">
            <img className="pr-1 mr-2" src="../img/icon/24-support.png" />
            </div>
            <div className="col-span-2 ">
              <p className="text-left text-base font-bold text-[#151515]">Hỗ trợ 24/7</p>
            </div>
            <div className="col-span-2 text-left">
              <span className=" text-base font-[500] text-[#515151]">Sẵn sàng hỗ trợ mọi lúc mọi nơi</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BenefitSection;
