import React, { useState, useContext  } from "react";
import { CartContext } from '../HomePage/Context/CartContext';

function ProductCard(props) {
  const [isHovered, setIsHovered] = useState(false);
  const { addToCart } = useContext(CartContext);
  
  return (
    <div
      className="card flex-grow-1 p-0 relative border border-transparent rounded hover:border-[#6B46FE]"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img
        src={props.product.img}
        className="h-[320px] p-0 object-cover rounded-t"
        alt="img"
      />
      <div className="card-body p-3 bg-white rounded-b text-left">
        <h5 className="overflow-hidden line-clamp-2 card-title text-[15px] font-medium text-[#151515]">
          {props.product.name}
        </h5>
        <div className="flex justify-between mt-5">
          <div>
            <p className="card-text text-lg font-bold text-[#151515]">
              <span className="align-super text-xs">&#8363;</span>
              {props.product.price_sale.toLocaleString()}
              <span className="text-muted">
                <span className="rounded-[31px] px-[7px] py-[3px] ml-1 text-[#ffffff] bg-[#6b46fe] font-bold text-xs">
                  -
                  {(
                    (props.product.price - props.product.price_sale) /
                    props.product.price
                  ).toFixed(2) * 100}
                  %
                </span>
              </span>
              <p className="card-text text-xs font-medium line-through text-[#aaaaaa]">
                <span className="align-super text-xs">&#8363;</span>
                {props.product.price.toLocaleString()}
              </p>
            </p>
          </div>
          {isHovered && (
            <div className="flex justify-center border-none">
              <button className="bg-[#6B46FE] rounded-full h-10 w-10 border-none flex justify-center items-center" onClick={() => addToCart(props.product.id)}>
                <img
                  src="../img/icon/shopping-cart-white.png"
                  className=" h-[24px] w-[24px]"
                  alt="img"
                />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProductCard;
