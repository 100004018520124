import React, { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import moment from "moment";
import { DatePicker, Image, Modal } from "antd";
import { ToastContainer, toast } from "react-toastify";
import { Pagination } from "antd";
import "react-toastify/dist/ReactToastify.css";
import UpsertBanner from "./UpsertBanner";

dayjs.extend(customParseFormat);
const dateFormat = "YYYY-MM-DD";
const { RangePicker } = DatePicker;

const LayoutBanner = (props) => {
  const [bannerPositions, setBannerPositions] = useState([]);
  const [listBanner, setListBanner] = useState([]);
  const [listBannerDisplay, setListBannerDisplay] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [bannerId, setBannerId] = useState(null);
  const [titleModal, setTitleModal] = useState("Tạo mới banner");
  const { confirm } = Modal;
  const childRef = useRef(null);
  const [formData, setFormData] = useState({
    position: 0,
    status: 2,
    name: "",
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().add(7, "days").format("YYYY-MM-DD"),
  });
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showDeleteConfirm = (banner_id) => {
    confirm({
      title: "Bạn chắc chắn muốn xóa Banner này?",
      icon: <ExclamationCircleFilled />,
      okText: "Xác nhận",
      okType: "danger",
      cancelText: "Hủy",
      onOk() {
        handleDeleteBanner(banner_id);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleChangeValue = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleChangeTime = (e) => {
    setFormData({
      ...formData,
      startDate:
        e === null
          ? moment().format("YYYY-MM-DD")
          : e[0].format().split("T")[0],
      endDate:
        e === null
          ? moment().add(7, "days").format("YYYY-MM-DD")
          : e[1].format().split("T")[0],
    });
  };

  const handleChangePaginateDisplay = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
    setListBannerDisplay(
      listBanner.slice((page - 1) * pageSize, page * pageSize)
    );
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
    setBannerId(0);
    setTitleModal("Tạo mới banner");
  };

  const searchBanners = async () => {
    try {
      const response = await props.props.searchBanners(formData);
      setListBanner(response.data);
      setListBannerDisplay(
        response.data.slice((page - 1) * pageSize, page * pageSize)
      );
    } catch (error) {
      toast.error("Có lỗi xảy ra, vui lòng thử lại sau!");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    searchBanners();
  };

  const callOnSubmit = () => {
    if (childRef.current) {
      childRef.current.onSubmit();
    }
  };

  const handleEditBanner = (banner_id) => {
    setIsModalOpen(true);
    setTitleModal("Cập nhật banner");
    setBannerId(banner_id);
  };

  const handleDeleteBanner = async (banner_id) => {
    try {
      await props.props.deleteBanner(banner_id);
    } catch (error) {
      toast.error("Xóa thất bại!");
    }
  };

  useEffect(() => {
    setBannerPositions(props.props.bannerPositions);
    searchBanners();
  }, [props.props.bannerPositions]);

  return (
    <div className=" px-5 mt-7">
      <form onSubmit={handleSubmit}>
        <div className="flex gap-5 ">
          <div className=" grid grid-cols-7 gap-4">
            <div className="w-full col-span-2">
              <label for="position">Tên banner</label>
              <div className="flex items-center">
                <input
                  id="name"
                  name="name"
                  type="text"
                  value={formData.name}
                  onChange={handleChangeValue}
                  className="text-[#888888] w-[522px] h-[45px] border-2 outline-none text-base px-[25px] py-[20px] rounded"
                  placeholder="Tên banner"
                />
              </div>
            </div>
            <div className="w-full">
              <label for="position">Vị trí</label>
              <div className="flex items-center">
                <select
                  id="position"
                  name="position"
                  value={formData.position}
                  onChange={handleChangeValue}
                  className="appearance-none text-[#020202] w-full h-[45px] border-2 outline-none text-base px-[15px] rounded"
                >
                  <option value={0}> Tất cả </option>
                  {bannerPositions.map((position, i) => {
                    return (
                      <option value={position.id}> {position.name} </option>
                    );
                  })}
                </select>
                <img
                  class="pointer-events-none ml-[-35px] h-6 w-6 "
                  src="../img/icon/arrow-right.png"
                  alt=""
                />
              </div>
            </div>
            <div className="w-full">
              <label for="status">Trạng thái</label>
              <div className="flex items-center pr-3">
                <select
                  id="status"
                  name="status"
                  value={formData.status}
                  onChange={handleChangeValue}
                  className="appearance-none text-[#020202] w-full h-[45px] border-2 outline-none text-base px-[25px] rounded"
                >
                  <option value={2}>Tất cả </option>
                  <option value={1}>Hoạt động </option>
                  <option value={0}>Không hoạt động </option>
                </select>
                <img
                  class="pointer-events-none ml-[-35px] h-6 w-6 "
                  src="../img/icon/arrow-right.png"
                  alt=""
                />
              </div>
            </div>
            <div className="w-full col-span-2">
              <label for="position">Thời gian hoạt động</label>
              <div className="flex items-center">
                <RangePicker
                  className="h-[45px]"
                  value={[
                    dayjs(formData.startDate, dateFormat),
                    dayjs(formData.endDate, dateFormat),
                  ]}
                  onChange={handleChangeTime}
                />
              </div>
            </div>
            <div className="w-full flex items-end">
              <div className="flex w-full flex-col justify-end">
                <button
                  type="submit"
                  className="mr-2 bg-red-600 w-full text-white font-bold py-2 px-4 border-none rounded-lg h-[45px]"
                >
                  Tìm kiếm
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="flex justify-between mt-5 border-b-2">
        <p className="mt-auto my-auto">Kết quả có {listBanner.length} banner</p>
        <button
          type="submit"
          onClick={showModal}
          className=" bg-white border-2 text-[#08c] font-bold py-2 px-4 rounded-lg h-[45px]"
        >
          Thêm mới Banner
        </button>
      </div>
      <div className=" grid text-center grid-cols-7 gap-4 mt-5">
        <div className="w-full">
          <label for="position">Hỉnh ảnh</label>
        </div>
        <div className="w-full">
          <label for="position">Vị trí</label>
        </div>
        <div className="w-full ">
          <label for="position">Tên banner</label>
        </div>
        <div className="w-full">
          <label for="position">Trạng thái</label>
        </div>
        <div className="w-full ">
          <label for="position">Ngày bắt đầu</label>
        </div>
        <div className="w-full ">
          <label for="position">Ngày kết thúc</label>
        </div>
        <div className="w-full">
          <label for="position">Hoạt động</label>
        </div>
      </div>

      {listBannerDisplay.map((banner, i) => {
        return (
          <div className=" grid grid-cols-7 gap-4 mt-5">
            <div className="w-full ">
              <div className="flex items-center justify-center mt-4 cursor-pointer">
                <Image
                  preview={{
                    mask: null,
                  }}
                  src={process.env.REACT_APP_API_URL + banner.source}
                  className="max-h-[40px] max-w-[90px]"
                  alt="Hình ảnh Banner"
                />
              </div>
            </div>
            <div className="w-full flex items-center justify-center mt-4">
              {banner.position.banner_position}
            </div>
            <div className="w-full flex items-center justify-center mt-4">
              {banner.name}
            </div>
            <div className="w-full flex items-center justify-center mt-4">
              <p className={banner.status ? "text-green-600" : "text-red-600"}>
                {banner.status ? "Hoạt động" : "Không hoạt động"}
              </p>
            </div>
            <div className="w-full flex items-center justify-center mt-4">
              {banner.start_date.split(" ")[0]}
            </div>
            <div className="w-full flex items-center justify-center mt-4">
              {banner.end_date.split(" ")[0]}
            </div>
            <div className="w-full flex justify-center items-center">
              <button
                type="button"
                onClick={() => handleEditBanner(banner.id)}
                className="mr-2 bg-none text-white w-10 font-bold py-2 px-0 rounded-full h-[45px]"
              >
                <EditOutlined style={{ fontSize: "20px", color: "#08c" }} />
              </button>
              <button
                type="button"
                onClick={() => showDeleteConfirm(banner.id)}
                className="mr-2 bg-none  text-white w-10 font-bold py-2 px-1 rounded-full h-[45px]"
              >
                <DeleteOutlined
                  style={{ fontSize: "20px", color: "#f5222d" }}
                />
              </button>
            </div>
          </div>
        );
      })}
      <Pagination
        defaultCurrent={1}
        total={listBanner.length}
        className="mt-4"
        onChange={handleChangePaginateDisplay}
      />
      <Modal
        title={titleModal}
        width={1000}
        open={isModalOpen}
        afterClose={searchBanners}
        onCancel={handleCancel}
        footer={[
          <div className="flex justify-center mx-auto gap-5">
            <button
              type="button"
              onClick={handleCancel}
              className="mr-2 bg-slate-400 text-white font-bold py-2 px-4 rounded"
            >
              Hủy
            </button>
            <button
              type="submit"
              onClick={() => callOnSubmit()}
              className="mr-2 bg-slate-400 text-white font-bold py-2 px-4 rounded"
            >
              Xác nhận
            </button>
          </div>,
        ]}
      >
        <UpsertBanner
          ref={childRef}
          bannerPositions={bannerPositions}
          dayjs={dayjs}
          moment={moment}
          bannerId={bannerId}
          handleUpsertBanner={props.props.handleUpsertBanner}
          handleGetBanner={props.props.handleGetBanner}
        />
      </Modal>
      <ToastContainer />
    </div>
  );
};
export default LayoutBanner;
